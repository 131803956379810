import React from 'react';
import styles from 'styles/login.module.scss';
import Header from 'components/header';
import LoginSVG from 'public/illustrations/sell-software-easily.svg';
import {ShowIfDesktop} from 'components/hoc/showIfDeviceWidth';

const AuthLayout = ({children}: {children: React.ReactNode}) => {
  return (
    <div className={styles.layout}>
      <Header hideNav white />
      <div className={styles.content}>
        <div className={styles.loginPanelContainer}>
          <div className={styles.inputSection}>{children}</div>
          <ShowIfDesktop>
            <div className={styles.illustrationContainer}>
              <LoginSVG />
              <h2>Sell software easily</h2>
            </div>
          </ShowIfDesktop>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
